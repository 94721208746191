import React, { useState } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SouthKoreaFlag from "../../images/flags/south-korea.svg";
import GlobalFlag from "../../images/flags/global.svg";
import FrenchFlag from "../../images/flags/french.svg";
import Content from "../Content";
import { HTMLContent } from "../Content/Content";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import "./style.scss";

const getLang = (lang) => {
  switch (lang) {
    case "kr":
      return { flag: SouthKoreaFlag, label: "한국어" };
    case "fr":
      return { flag: FrenchFlag, label: "Français" };
    default:
      return { flag: GlobalFlag, label: "English" };
  }
};

const PrivacyPolicy = ({ location, isOpen, onClose }) => {
  const PostContent = HTMLContent || Content;
  const data = useStaticQuery(graphql`
    query PrivacyPolicyQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
        html
        frontmatter {
          title
        }
      }
    }
  `);

  return (
    <ReactModal isOpen={isOpen}>
      <div className="modal">
        <div className="modal-backdrop" onClick={onClose} />
        <div className="modal-content">
          <div className="modal-close">
            <Icon icon={faTimes} fixedWidth size="lg" onClick={() => setMenuIsOpen(false)} />
          </div>
          <PostContent content={data?.markdownRemark?.html} className="body-large typography" />
        </div>
      </div>
    </ReactModal>
  );
};

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

PrivacyPolicy.defaultProps = {
  isOpen: false,
};

export default PrivacyPolicy;
