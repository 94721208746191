import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "gatsby";
import ReactModal from "react-modal";
import "./style.scss";
import Typography from "../Typography";
import footerNavigation from "../../data/footer.json";
import Grid from "../Grid";
import Input from "../Input";
import Button from "../Button";
import LogoGroup from "../LogoGroup";
// import LogoInverted from "../../images/logo-inverted.svg";
import EmailIcon from "../../images/icons/email.svg";
import GlobalFlag from "../../images/flags/global.svg";
import SouthKoreaFlag from "../../images/flags/south-korea.svg";
import RussiaFlag from "../../images/flags/russia.svg";
import VietnamFlag from "../../images/flags/vietnam.svg";
import TurkeyFlag from "../../images/flags/turkey.svg";
import { CookieContext } from "../Layout";
import navigation from "../../data/navigation.json";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import PrivacyPolicy from "../PrivacyPolicy";

const NewFooter = ({ location }) => {
  const [showTelegramDialog, setShowTelegramDialog] = useState(false);
  const { handleResetCookieConsent } = useContext(CookieContext);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  if (typeof document !== "undefined") {
    ReactModal.setAppElement(window.document.getElementById("root"));
  }

  const urlLanguagePart = (location?.pathname?.split("/") ?? [])[1];
  const language = Object.keys(navigation).includes(urlLanguagePart) ? urlLanguagePart : "en";
  const footerTranslations = language && footerNavigation[language] ? footerNavigation[language] : footerNavigation.en;
  const { items: menus, newsletter, telegramDialog, copyright } = footerTranslations || {};

  return (
    <footer data-aos="fade-up">
      <Grid container className="pt-120 pb-96">
        <Grid row className="search">
          <Grid className="search-content" column xs={12} sm={11} md={8} lg={6} xl={6}>
            <div className="search-content-tittle">
              <Typography variant="h1" component="div" color="white" className="mb-8">
                {newsletter?.title}
              </Typography>
            </div>
            <div className="search-content-subtitle">
              <Typography className="mb-16" variant="subtitle" component="div" color="white" weight="thin">
                {newsletter?.subtitle}
              </Typography>
            </div>
            <div className="signUp">
              <button
                className={clsx("btn align-items-end btn-type1")}
                type="text"
              >
                <a
                  href="https://medium.com/m/signin?actionUrl=%2F_%2Fapi%2Fsubscriptions%2Fnewsletters%2F79c6216e1171&operation=register&redirect=https%3A%2F%2Flinear-finance.medium.com%2F&newsletterV3=a5aed4714586&newsletterV3Id=79c6216e1171&user=Linear+Finance&userId=a5aed4714586&source=---two_column_layout_sidebar-----------------------subscribe_user-----------"
                  target="_blank"
                >
                  <span className="btn-label">{newsletter?.buttonLabel}</span>
                </a>
              </button>
            </div>
            {/* <form
              className="d-flex mx-n8"
              style={{
                marginTop: "70px",
              }}
              action="https://finance.us7.list-manage.com/subscribe/post?u=32fc7c972574ff3b667d37599&amp;id=2fd9a15e72"
              method="post"
              target="_blank"
              rel="noreferrer"
            >
              <div className="search-content-input  px-8 flex-fill">
                <EmailIcon className="search-content-input-icon" />
                <Input
                  id="newsletter-email"
                  name="EMAIL"
                  placeholder={newsletter?.placeholder}
                  showLabel={false}
                  label={newsletter?.label}
                  className="font-email"
                />
              </div>
              <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
                <input type="text" name="b_32fc7c972574ff3b667d37599_2fd9a15e72" tabIndex="-1" value="" />
              </div>
              <div className="px-8">
                <button
                  className={clsx("btn align-items-end btn-type1 btnTransfrom")}
                  style={{
                    transform: "translateX(-26px)",
                  }}
                  type="text"
                >
                  <span className="btn-label">{newsletter?.buttonLabel}</span>
                </button>
              </div>
            </form> */}
          </Grid>
          <PreviewCompatibleImage className="search-bg" imageInfo={{ image: "../img/home/footer.png" }} />
        </Grid>
      </Grid>
      <section className="bg-primary-100 text-white pt-80 pt-sm-48 pb-24">
        <Grid container>
          <Grid row>
            {menus.map((menu) => (
              <Grid key={menu?.label} column xs={12} sm={6} lg={3} className="mb-32 mb-lg-0">
                <Typography variant="subtitle" color="transparent" weight="light" className="text-uppercase  mb-8">
                  {menu.label}
                </Typography>
                <ul className="list-unstyled">
                  {menu.items.map((item) => {
                    if (item?.url === "open-telegram-dialog") {
                      return (
                        <li className="mb-8" key={item?.url}>
                          <Typography
                            component="button"
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setShowTelegramDialog(true);
                            }}
                            color="white"
                            className="btn btn-link p-0"
                            title={item?.label}
                          >
                            {item?.label}
                          </Typography>
                        </li>
                      );
                    }
                    if (item?.url === "change-cookie-settings") {
                      return (
                        <li className="mb-8" key={item?.url}>
                          <Typography
                            component="button"
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              handleResetCookieConsent();
                            }}
                            color="white"
                            className="btn btn-link p-0"
                            title={item?.label}
                          >
                            {item?.label}
                          </Typography>
                        </li>
                      );
                    }
                    if (item?.url === "privacy-policy") {
                      return (
                        <li className="mb-8" key={item?.url}>
                          <Typography
                            component="button"
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setIsPolicyModalOpen(true);
                            }}
                            color="white"
                            className="btn btn-link p-0"
                            title={item?.label}
                          >
                            {item?.label}
                          </Typography>
                          <PrivacyPolicy isOpen={isPolicyModalOpen} onClose={() => setIsPolicyModalOpen(false)} />
                        </li>
                      );
                    }

                    return (
                      <li className="mb-8" key={item?.url}>
                        <Typography
                          {...(item?.external ? { href: item?.url } : { to: item?.url })}
                          component={item?.external ? "a" : Link}
                          color="white"
                          target={item?.newWindow ? "_blank" : null}
                          rel={item?.newWindow ? "noreferrer" : null}
                          title={item?.label}
                        >
                          {item?.label}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <div className="container foot">
          <LogoGroup
            style={{
              width: "160px",
              height: "45px",
            }}
          />
          <Typography color="neutral-30" className=" foot-text" component="div">
            © {new Date().getFullYear()} {copyright}
          </Typography>
        </div>
      </section>
      <ReactModal
        isOpen={showTelegramDialog}
        shouldCloseOnOverlayClick
        onRequestClose={() => setShowTelegramDialog(false)}
        closeTimeoutMS={200}
        preventScroll
      >
        <Button
          color="link"
          rounded
          disableOverlay
          onClick={() => setShowTelegramDialog(false)}
          className="position-absolute p-8 top-12 right-16"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" width={14} height={14}>
            <path
              fill="#99999A"
              fillRule="evenodd"
              d="M13 1c.2.2.2.4 0 .6v.1L7.7 7l5.3 5.3a.5.5 0 01-.6.8h-.1L7 7.6 1.7 13a.5.5 0 01-.8-.6v-.1L6.4 7 1 1.7a.5.5 0 01.6-.8h.1L7 6.4 12.3 1c.2-.2.5-.2.7 0z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
        <Typography variant="h2" className="mt-20 mb-48">
          Join our Telegram community!
          {telegramDialog?.title}
        </Typography>

        <div className="d-flex mt-32 mb-24">
          <div className="mr-40">
            <div className="d-flex align-items-center">
              <GlobalFlag width={48} height={32} className="mr-16 shadow rounded" />
              <div>
                <Typography color="neutral-50" variant="paragraph-2" weight="bold">
                  {telegramDialog?.global}
                </Typography>
                <Typography
                  variant="paragraph-2"
                  component="a"
                  href="https://t.me/LinearExchangeCommunity"
                  className="d-flex align-items-center"
                  target="_blank"
                >
                  <span>{telegramDialog?.joinNow}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 8"
                    width="6"
                    height="8"
                    className="ml-4 mt-3"
                  >
                    <path
                      fill="#BABABA"
                      fillRule="evenodd"
                      d="M1.4.1a.5.5 0 00-.7.7L4.5 4 .7 7.1c-.2.2-.3.5-.1.7.2.2.5.3.7.1l4.2-3.6c.2-.2.2-.5 0-.7L1.4 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center mt-32">
              <SouthKoreaFlag width={48} height={32} className="mr-16 shadow rounded" />
              <div>
                <Typography color="neutral-50" variant="paragraph-2" weight="bold">
                  {telegramDialog?.southKorea}
                </Typography>
                <Typography
                  variant="paragraph-2"
                  component="a"
                  href="https://t.me/LinearFinance_Korea"
                  className="d-flex align-items-center"
                  target="_blank"
                >
                  <span>{telegramDialog?.joinNow}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 8"
                    width="6"
                    height="8"
                    className="ml-4 mt-3"
                  >
                    <path
                      fill="#BABABA"
                      fillRule="evenodd"
                      d="M1.4.1a.5.5 0 00-.7.7L4.5 4 .7 7.1c-.2.2-.3.5-.1.7.2.2.5.3.7.1l4.2-3.6c.2-.2.2-.5 0-.7L1.4 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Typography>
              </div>
            </div>
          </div>
          <div className="ml-40">
            <div className="d-flex align-items-center">
              <VietnamFlag width={48} height={32} className="mr-16 shadow rounded" />
              <div>
                <Typography color="neutral-50" variant="paragraph-2" weight="bold">
                  {telegramDialog?.vietnam}
                </Typography>
                <Typography
                  variant="paragraph-2"
                  component="a"
                  href="https://t.me/linearfinancevietnam"
                  className="d-flex align-items-center"
                  target="_blank"
                >
                  <span>{telegramDialog?.joinNow}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 8"
                    width="6"
                    height="8"
                    className="ml-4 mt-3"
                  >
                    <path
                      fill="#BABABA"
                      fillRule="evenodd"
                      d="M1.4.1a.5.5 0 00-.7.7L4.5 4 .7 7.1c-.2.2-.3.5-.1.7.2.2.5.3.7.1l4.2-3.6c.2-.2.2-.5 0-.7L1.4 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center mt-32">
              <TurkeyFlag width={48} height={32} className="mr-16 shadow rounded" />
              <div>
                <Typography color="neutral-50" variant="paragraph-2" weight="bold">
                  {telegramDialog?.turkey}
                </Typography>
                <Typography
                  variant="paragraph-2"
                  component="a"
                  href="https://t.me/linearfinanceturkey"
                  className="d-flex align-items-center"
                  target="_blank"
                >
                  <span>{telegramDialog?.joinNow}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 8"
                    width="6"
                    height="8"
                    className="ml-4 mt-3"
                  >
                    <path
                      fill="#BABABA"
                      fillRule="evenodd"
                      d="M1.4.1a.5.5 0 00-.7.7L4.5 4 .7 7.1c-.2.2-.3.5-.1.7.2.2.5.3.7.1l4.2-3.6c.2-.2.2-.5 0-.7L1.4 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </footer>
  );
};

NewFooter.propTypes = {
  location: PropTypes.object,
};

NewFooter.defaultProps = {
  location: undefined,
};

export default NewFooter;
