import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./style.scss";

const variantMapping = {
  "display-1": "h1",
  h1: "h1",
  h2: "h2",
  "paragraph-1": "p",
  "paragraph-2": "p",
  overline: "p",
  subtitle: "div",
  caption: "div",
  "button-1": "div",
  "button-2": "div",
  "button-3": "div",
  "button-4": "div",
  "introduction-1": "div",
  "introduction-2": "div",
  dateTittle: "div",
  tabItem: "div",
  tabTittle: "div",
  cardHeadTittle: "div",
  listItem: "div",
  listItemExternal: "div",
};

const Typography = ({
  align,
  variant,
  component,
  color,
  weight,
  disableMargin,
  fontFamily,
  className,
  children,
  ...props
}) => {
  const Component = component || variantMapping[variant] || "span";

  return (
    <Component
      {...props}
      className={clsx(
        "typography",
        {
          [variant]: Component !== variant,
          [`font-family-${fontFamily}`]: fontFamily,
          [`text-${color}`]: color,
          [`weight-${weight}`]: weight,
          [`text-${align}`]: align,
          "my-0": disableMargin,
        },
        className
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;

Typography.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right", undefined]),
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "primary-100",
    "primary-50",
    "primary-40",
    "danger",
    "warning",
    "success",
    "info",
    "white",
    "text-primary",
    "text-secondary",
    "heading",
    "black",
    "neutral-0",
    "neutral-10",
    "neutral-20",
    "neutral-30",
    "neutral-40",
    "neutral-50",
    "transparent",
    undefined,
  ]),
  component: PropTypes.elementType,
  disableMargin: PropTypes.bool,
  fontFamily: PropTypes.oneOf(["base", "headings", undefined]),
  variant: PropTypes.oneOf([
    "display-1",
    "h1",
    "h2",
    "paragraph-1",
    "paragraph-2",
    "subtitle",
    "caption",
    "button-1",
    "button-2",
    "button-3",
    "button-4",
    "overline",
    "sr-only",
    "inherit",
    "tittle-1",
    "tittle-2",
    "introduction-1",
    "introduction-2",
    "dateTittle",
    "tabItem",
    "tabTittle",
    "cardHeadTittle",
    "listItem",
    "listItemExternal",
  ]),
  weight: PropTypes.oneOf([
    "extra-light",
    "light",
    "thin",
    "normal",
    "medium",
    "semi-bold",
    "bold",
    "extra-bold",
    "black",
    undefined,
  ]),
};

Typography.defaultProps = {
  align: undefined,
  className: undefined,
  children: undefined,
  color: undefined,
  component: undefined,
  disableMargin: true,
  fontFamily: undefined,
  variant: "paragraph-1",
  weight: undefined,
};
