import React, { useState } from "react";
import PropTypes from "prop-types";
import { useKey } from "react-use";
import clsx from "clsx";
import { Link } from "gatsby";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faMedium, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import SouthKoreaFlag from "../../images/flags/south-korea.svg";
import GlobalFlag from "../../images/flags/global.svg";
import FrenchFlag from "../../images/flags/french.svg";
import ChevronDown from "../../images/icons/chevron-down.svg";

import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import "./style.scss";
import navigation from "../../data/navigation.json";
import LogoGroup from "../LogoGroup";

const getLang = (lang) => {
  switch (lang) {
    case "kr":
      return { flag: SouthKoreaFlag, label: "한국어" };
    case "fr":
      return { flag: FrenchFlag, label: "Français" };
    default:
      return { flag: GlobalFlag, label: "English" };
  }
};

const Header = ({ location, variant, color, bgColor, title }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [languageIsOpen, setLanguageIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useKey("Escape", () => setMenuIsOpen(false));

  const urlLanguagePart = (location?.pathname?.split("/") ?? [])[1];
  const language = Object.keys(navigation).includes(urlLanguagePart) ? urlLanguagePart : "en";
  const headerTranslations = navigation[language];
  const { items: menu, button } = headerTranslations || {};
  const [langChoice, setLangChoice] = useState("en");
  const { flag: Flag, label: langLabel } = getLang(language);

  const modalContentStyle = {
    display: "flex",
    flexDirection: "row-reverse",
    maxWidth: "100vw",
    height: "100vh",
    zIndex: "1040",
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: language ?? "en" }} />
      <nav
        style={{
          visibility: `${openMenu ? "hidden" : "visible"} `,
          position: "sticky",
        }}
        className={clsx(`navbar ${variant} navbar-light flex-column flex-md-row`)}
      >
        <div className="container py-20 py-lg-24">
          <Link className=" text-center" to={language !== "en" ? `/${language}/` : "/"} title={title}>
            <LogoGroup
              variant={"home-logo"}
              style={{
                width: "199px",
                height: "56px",
              }}
            />
          </Link>
          <div className={clsx("navbar-drawer", { in: menuIsOpen })}>
            <div className="navbar-drawer-container">
              <div className="navbar-drawer-container-close">
                <Icon icon={faTimes} fixedWidth size="lg" onClick={() => setMenuIsOpen(false)} />
              </div>
              <div className="navbar-drawer-container-content">
                <LogoGroup
                  className="navbar-drawer-container-content-logo"
                  style={{
                    width: "199px",
                    height: "56px",
                  }}
                />
                <ul className="navbar-drawer-container-content-list list-unstyled ">
                  {menu.map((item) => (
                    <li
                      className={clsx(
                        "navbar-drawer-container-content-list-item",
                        item?.external ? "listItemExternal" : "listItem"
                      )}
                      key={item?.url}
                    >
                      {item?.external ? (
                        <Typography
                          component="a"
                          href={item?.url}
                          title={item?.label}
                          variant="listItemExternal"
                          onClick={() => setMenuIsOpen(false)}
                          className="nav-item"
                        >
                          {item?.label}
                        </Typography>
                      ) : (
                        <Typography
                          variant="listItem"
                          component={Link}
                          to={item?.url}
                          title={item?.label}
                          onClick={() => setMenuIsOpen(false)}
                        >
                          {item?.label}
                        </Typography>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="navbar-drawer-container-content-bottomWrapper">
                  <div className="navbar-drawer-container-content-language">
                    <div
                      onClick={() => {
                        setLanguageIsOpen(!languageIsOpen);
                      }}
                      className="navbar-drawer-container-content-language-button"
                    >
                      <div className="navbar-drawer-container-content-language-button-flag">
                        <Flag></Flag>
                      </div>
                      <div className="navbar-drawer-container-content-language-button-langLabel">{langLabel}</div>
                      <div className="navbar-drawer-container-content-language-button-chevronDown">
                        <ChevronDown
                          style={{
                            transform: languageIsOpen ? "rotate(180deg)" : "rotate(-90deg)",
                          }}
                        ></ChevronDown>
                      </div>
                    </div>
                    {languageIsOpen && (
                      <div className="navbar-drawer-container-content-language-menu">
                        {Object.keys(navigation).map((lang) => {
                          const currentLang = getLang(lang);
                          const { flag: CountryFlag, label } = currentLang;

                          return (
                            <Link
                              to={`/${lang !== "en" ? lang : ""}`}
                              onClick={() => {
                                setOpenMenu(false);
                              }}
                              className="d-flex align-items-center navbar-drawer-container-content-language-menu-item"
                            >
                              <CountryFlag width={40} height={32} className="flex-shrink-0" />{" "}
                              <span className="d-flex ml-16">{label}</span>
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <a href={`https://twitter.com/LinearFinance`} rel="noopener noreferrer" target="_blank">
                    <Icon icon={faSquareXTwitter} fixedWidth size="2x" color="white" />
                  </a>
                  <a href={`https://discord.gg/nvCk356bky`}>
                    <Icon icon={faDiscord} fixedWidth size="2x" color="white" />
                  </a>
                  <a href={`https://linear-finance.medium.com/`}>
                    <Icon icon={faMedium} fixedWidth size="2x" color="white" />
                  </a>
                </div>
              </div>
            </div>
            <div className="navbar-drawer-backdrop" onClick={() => setMenuIsOpen(false)} aria-hidden />
          </div>
          <Button
            color="link"
            className={"p-12 text-white"}
            type="button"
            onClick={() => setMenuIsOpen(true)}
            disableOverlay
          >
            <span className="sr-only">Open menu</span>
            <Icon icon={faBars} size="lg" />
          </Button>
        </div>
      </nav>
    </>
  );
};

Header.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  bgColor: PropTypes.oneOf([
    "white",
    "black",
    "muted",
    "primary",
    "secondary",
    "success",
    "warning",
    "info",
    "danger",
    "transparent",
  ]),
  location: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["default", "sticky-top", "fixed-top", "fixed-bottom"]),
};

Header.defaultProps = {
  color: "light",
  bgColor: "white",
  location: undefined,
  title: undefined,
  variant: "sticky-top",
};

export default Header;
