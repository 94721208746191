import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import clsx from "clsx";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const LogoGroup = ({ variant, className, children, component, ...props }) => {
  const Component = component || "div";
  return (
    <Component
      {...props}
      className={clsx(
        {
          [variant]: Component !== variant,
        },
        className
      )}
    >
      {variant === "home-logo" && (
        <>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-1`)}
            imageInfo={{ image: `/img/logos/home_logop1.png` }}
          ></PreviewCompatibleImage>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-2`)}
            imageInfo={{ image: `/img/logos/home_logop2.png` }}
          ></PreviewCompatibleImage>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-3`)}
            imageInfo={{ image: `/img/logos/home_logop3.png` }}
          ></PreviewCompatibleImage>
        </>
      )}
      {variant === "home-logo-black" && (
        <>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-1`)}
            imageInfo={{ image: `/img/logos/home_logop1.png` }}
          ></PreviewCompatibleImage>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-2`)}
            imageInfo={{ image: `/img/logos/home_logop2.png` }}
          ></PreviewCompatibleImage>
          <PreviewCompatibleImage
            className={clsx(`${variant}-img-3`)}
            imageInfo={{ image: `/img/logos/home_logop3_black.png` }}
          ></PreviewCompatibleImage>
        </>
      )}
      {children}
    </Component>
  );
};

export default LogoGroup;

LogoGroup.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

LogoGroup.defaultProps = {
  variant: "home-logo",
  children: undefined,
  className: undefined,
};
