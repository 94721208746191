import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Typography from "../Typography";
import "./style.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Content from "../Content";
import { HTMLContent } from "../Content/Content";
import Icon from "../Icon"; // Ensure Icon is correctly imported
import Button from "../Button/Button";

const Announcement = () => {
  const [isDiscarded, setIsDiscarded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const PostContent = HTMLContent || Content;
  const [locale, setLocale] = useState();
  const [localizedData, setLocalizedData] = useState();


  const handleDiscard = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("marketplaceNews", "true");
    }
    setIsDiscarded(true);
  };

  const handleLinkDiscard = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("marketplaceNews", "true");
    }
    setIsDiscarded(true);
    window.open("marketplace", "_blank", "noopener,noreferrer");
  }

  const data = useStaticQuery(graphql`
    query AnnouncementQuery {
      allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "announcement" } } }) {
        nodes {
          html
          frontmatter {
            title
            locale
            announcement
            button
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const discarded = localStorage.getItem("marketplaceNews");
      if (discarded != null) return;
      setIsDiscarded(false);
      setIsLoading(false);
      const locale = (location?.pathname?.split("/") ?? [])[1];
      setLocale(locale ? locale : "en")
    }
  }, []);

  useEffect(() => {
    if (locale == null) return;
    setLocalizedData(data.allMarkdownRemark.nodes.find(
    node => node.frontmatter.locale === locale
    ));
  }, [locale])

  if (isDiscarded || isLoading || !localizedData) return null;

  return (

    <ReactModal isOpen={!isDiscarded}>
      <div className="modal">
        <div className="modal-backdrop" onClick={handleDiscard} />
        <div className="modal-content">
          <div className="modal-close">
            <Icon icon={faTimes} fixedWidth size="lg" onClick={handleDiscard} />
          </div>
          <div className="title">
            <Typography align={"center"} variant={"display-1"}>{localizedData.frontmatter.announcement}</Typography>
          </div>
          <PostContent content={localizedData?.html} className="body-large typography announcementText" />
          <div className="content-Button">
            <Button size={4} onClick={handleLinkDiscard}>
              {localizedData.frontmatter.button}
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Announcement;
